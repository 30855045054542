import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lib/noop'
import {
  ActionButton,
  ActionCampaigns,
  AsteriskExplain,
  Financing,
  OpenSimulator,
  FinancialData,
  ActionTime,
  LineTrace,
  ActionContactCompany,
  FinishedPropertyModal,
  NoticeInformation,
  ActionValuesContainer,
  CountDown,
  AuctionDate,
  Button,
} from 'components'
import TypeformModal from 'components/TypeformModal/TypeformModal'
import { AuctionContainer } from 'containers'
import classNames from 'classnames'
import { useSimulatorForm } from 'store'
import { extractNameCampaign, SALE_CAMPAIGNS } from 'service/campaigns'
import { useCampaigns } from 'hooks'
import { isBestProposal as checkBestProposal } from 'store/property/utils/general'
import { normalizeStringAndChangeSpaces } from 'utils/normalizeString'
import { CAIXA_KEY, formatDate } from './utils'
import styles from './CallToAction.module.scss'

const CallToAction = ({
  estateData,
  proposalAction,
  contactUsAction,
  simulatorAction,
  scheduleAction,
  preview,
  propertyIsPreActive,
  setPropertyIsPreActive,
}) => {
  const { setField } = useSimulatorForm()
  const [modal, setModal] = React.useState('')
  const [displayCountdownPreActive, setDisplayCountdownPreActive] = useState(
    false
  )
  const { hasActiveCampaign, campaigns, isAnActiveCampaign } = useCampaigns()

  const {
    appraisedPrice,
    appraisedLabel,
    mainValue,
    mainValueWithoutComission,
    mainValueLabel,
    minInstallmentEntry,
    callToActionConsiderations,
    showDiscount,
    hideValue,
    desagio,
    showVisit,
    showContactUs,
    callToActionLabel,
    juridical,
    saleCategory,
    fullContactButton,
    contactButtonLabel,
    formas_pagamento_card,
    showAuctionDate,
    showMultipleAuctionDate,
    isDirectInstallment,
    isConcurrenceOver,
    auctionInfo,
    activeContactUs,
    callToActionUrl,
    showFinanceEstate,
    hideConditions,
    salesman,
    contactInfo,
    isAuction,
    isPublicCompetition,
    propertyId,
    isSacre,
    campaignInfo,
    tags,
    saleType,
    propertySmallId,
    category,
    purpose,
    preActive,
    preActiveExpiration,
    wasClosed,
    wasSold,
    campaignsData,
    conditions,
  } = estateData

  useLayoutEffect(() => {
    setTimeout(() => {
      setDisplayCountdownPreActive(preActive)
    }, 1500)
    // eslint-disable-next-line
  }, [])

  if (!estateData) {
    return null
  }

  const preActiveDate = preActiveExpiration
    ? preActiveExpiration.split(' ')[0]
    : ''
  const preActiveHour = preActiveExpiration
    ? preActiveExpiration.split(' ')[1]
    : ''

  const endDate = formatDate(preActiveDate, preActiveHour, propertyIsPreActive)

  const {
    values: { campaignTotalValue },
  } = campaignInfo

  const hasPromotion = Boolean(campaignTotalValue && hasActiveCampaign())

  const isJudicialSale = saleCategory === 'venda_judicial'
  const isBestProposal = checkBestProposal(saleType)

  const callActionActive = !preview && !(isBestProposal && !auctionInfo[0])

  const appraisedLowerThanMain = appraisedPrice < mainValue

  const hasNovemberMagic =
    tags && tags.some(condition => /Novembro Mágico/i.test(condition))

  const getNameCampaign = () => {
    if (hasNovemberMagic) return 'Saldão'
    return extractNameCampaign(estateData, campaigns)
  }

  const handleActionButton = () => {
    if (/Encerrado/i.test(callToActionLabel)) {
      return setModal('FinishedModal')
    }
    if (callToActionUrl) {
      return setModal('AuctionModal')
    }

    if (/Fale Conosco/i.test(callToActionLabel)) {
      return contactUsAction()
    }

    return proposalAction()
  }

  const handleFinancingClick = () => {
    setField('balance_amount', mainValueWithoutComission.toFixed(0))
    setField('property_id', propertyId)
    setField('entrance_value', minInstallmentEntry)
    simulatorAction()
  }

  const titleCountDown = hasActiveCampaign()
    ? `${getNameCampaign()} acaba em:`
    : ''

  const isFinancing = conditions.some(
    condition => condition.title === 'Financiamento Imobiliário'
  )

  const checkSaleTypeAndReturnLabelAction = () => {
    const labels = {
      melhor_proposta: 'participe_da_melhor_proposta',
    }

    return labels[normalizeStringAndChangeSpaces(saleType)] || ''
  }
  const checkSaleTypeAndReturnLabelContact = () => {
    const labels = {
      melhor_proposta: 'fale_conosco_MP',
      venda_direta: 'fale_conosco_VD',
    }

    return labels[normalizeStringAndChangeSpaces(saleType)] || ''
  }

  const isDirectSale = /Venda(| )Direta/i.test(saleType)

  const isFacaSuaProposta =
    isAnActiveCampaign(SALE_CAMPAIGNS.facaSuaProposta) &&
    campaignsData?.some(c => SALE_CAMPAIGNS.facaSuaProposta.test(c?.codigo))

  return (
    <>
      <ActionCampaigns
        titleCountDown={titleCountDown}
        campaignsData={campaignsData}
      />

      <div className={styles.card}>
        <AuctionContainer
          opened={modal === 'AuctionModal'}
          closeModal={() => setModal('')}
          url={callToActionUrl}
        />

        <FinishedPropertyModal
          opened={modal === 'FinishedModal'}
          closeModal={() => setModal('')}
          contactInfo={{ name: contactInfo.name, phone: contactInfo.phone }}
        />
        <ActionValuesContainer
          hasPromotion={hasPromotion}
          isJudicialSale={isJudicialSale}
          juridical={juridical}
          mainValue={mainValue}
          appraisedPrice={appraisedPrice}
          appraisedLowerThanMain={appraisedLowerThanMain}
          appraisedLabel={appraisedLabel}
          showDiscount={showDiscount}
          desagio={desagio}
          hideValue={hideValue}
          mainValueLabel={mainValueLabel}
          callToActionConsiderations={callToActionConsiderations}
          campaignTotalValue={campaignTotalValue}
          getNameCampaign={getNameCampaign}
        />
        <AsteriskExplain
          show={!!callToActionConsiderations.length}
          callToActionConsiderations={callToActionConsiderations}
        />

        <ActionTime
          show={
            showAuctionDate &&
            isPublicCompetition &&
            !showMultipleAuctionDate &&
            !wasClosed &&
            !wasSold &&
            !propertyIsPreActive
          }
          isBestProposal={isBestProposal}
          auctionInfo={auctionInfo}
        />

        {showMultipleAuctionDate &&
          Object.values(auctionInfo).map((auction, index) => (
            <AuctionDate
              label={`${index + 1}º Leilão`}
              date={auction.data}
              hour={auction.hora}
            />
          ))}

        <LineTrace />

        <NoticeInformation show={salesman.id === CAIXA_KEY} />

        <FinancialData
          show={!hideConditions}
          paymentsMethods={formas_pagamento_card}
        />

        <OpenSimulator
          show={isDirectInstallment && isSacre && !preview}
          onClick={handleFinancingClick}
        />

        <Financing
          show={showFinanceEstate && !preview}
          property={{
            mainValue,
            propertySmallId,
            category,
            purpose,
            salesman,
            isFinancing,
          }}
        />

        <div
          className={classNames(
            {
              [styles.buttonContainer]: showContactUs || showVisit,
            },
            {
              [styles.singleButtonContainer]: !showContactUs && !showVisit,
            },
            {
              [styles.none]: preview,
            }
          )}
        >
          {!!endDate && propertyIsPreActive && displayCountdownPreActive && (
            <>
              <span className={styles.subTitleCampaign}>
                Liberação para compra em:
              </span>
              <CountDown
                endDate={endDate}
                title=" "
                onComplete={() => setPropertyIsPreActive(false)}
              />
            </>
          )}
          {isDirectSale && isFacaSuaProposta && (
            <Button
              className={styles.facaSuaPropostaButton}
              onClick={() => setModal('facaSuaProposta')}
            >
              Faça sua proposta
            </Button>
          )}
          <ActionButton
            show={callActionActive}
            callToActionLabel={callToActionLabel}
            callToActionUrl={callToActionUrl}
            isConcurrenceOver={isConcurrenceOver}
            isAuction={isAuction}
            handleActionButton={handleActionButton}
            isPreActive={propertyIsPreActive}
            idButton={checkSaleTypeAndReturnLabelAction()}
          />
          <ActionContactCompany
            show={!preview}
            preview={preview}
            activeContactUs={activeContactUs}
            showVisit={showVisit}
            scheduleAction={scheduleAction}
            contactUsAction={contactUsAction}
            showContactUs={showContactUs}
            fullContactButton={fullContactButton}
            callToActionLabel={callToActionLabel}
            contactButtonLabel={contactButtonLabel}
            idButton={checkSaleTypeAndReturnLabelContact()}
          />
        </div>
      </div>
      <TypeformModal
        opened={modal === 'facaSuaProposta'}
        closeModal={setModal}
        pipefyUrl={`https://app.pipefy.com/public/form/90QONEuD?embedded=true&insira_o_c_digo_do_im_vel=${propertySmallId}`}
      />
    </>
  )
}

CallToAction.propTypes = {
  estateData: PropTypes.shape({
    formas_pagamento_card: PropTypes.arrayOf(PropTypes.shape({})),
    callToActionConsiderations: PropTypes.arrayOf(PropTypes.string),
    juridical: PropTypes.shape({}).isRequired,
    saleCategory: PropTypes.string.isRequired,
    appraisedPrice: PropTypes.number.isRequired,
    appraisedLabel: PropTypes.string.isRequired,
    mainValue: PropTypes.number.isRequired,
    mainValueWithoutComission: PropTypes.number.isRequired,
    minInstallmentEntry: PropTypes.number.isRequired,
    desagio: PropTypes.number.isRequired,
    isConcurrenceOver: PropTypes.bool.isRequired,
    propertyId: PropTypes.string.isRequired,
    auctionInfo: PropTypes.arrayOf(PropTypes.string),
    isSacre: PropTypes.bool.isRequired,
    activeContactUs: PropTypes.bool.isRequired,
    mainValueLabel: PropTypes.string.isRequired,
    callToActionLabel: PropTypes.string.isRequired,
    contactButtonLabel: PropTypes.string.isRequired,
    callToActionUrl: PropTypes.string,
    auctionLabel: PropTypes.string,
    auctionDate: PropTypes.string,
    auctionHour: PropTypes.string,
    salesman: PropTypes.objectOf(PropTypes.string),
    saleType: PropTypes.string.isRequired,
    showDiscount: PropTypes.bool.isRequired,
    hideValue: PropTypes.bool.isRequired,
    showProposal: PropTypes.bool.isRequired,
    showVisit: PropTypes.bool.isRequired,
    showContactUs: PropTypes.bool.isRequired,
    fullContactButton: PropTypes.bool.isRequired,
    showAuctionDate: PropTypes.bool.isRequired,
    showMultipleAuctionDate: PropTypes.bool.isRequired,
    showFinanceEstate: PropTypes.bool.isRequired,
    isDirectInstallment: PropTypes.bool.isRequired,
    hideConditions: PropTypes.bool.isRequired,
    propertySmallId: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    contactInfo: PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
    }).isRequired,
    isAuction: PropTypes.bool.isRequired,
    isPublicCompetition: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    campaignInfo: PropTypes.shape({
      values: PropTypes.shape({
        campaignTotalValue: PropTypes.number.isRequired,
      }),
    }).isRequired,
    purpose: PropTypes.string.isRequired,
    preActive: PropTypes.bool.isRequired,
    preActiveExpiration: PropTypes.arrayOf([]).isRequired,
    wasClosed: PropTypes.bool.isRequired,
    wasSold: PropTypes.bool.isRequired,
    campaignsData: PropTypes.arrayOf([]).isRequired,
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  proposalAction: PropTypes.func,
  contactUsAction: PropTypes.func,
  scheduleAction: PropTypes.func,
  simulatorAction: PropTypes.func,
  preview: PropTypes.bool,
  propertyIsPreActive: PropTypes.bool.isRequired,
  setPropertyIsPreActive: PropTypes.func.isRequired,
}

CallToAction.defaultProps = {
  proposalAction: noop,
  contactUsAction: noop,
  scheduleAction: noop,
  simulatorAction: noop,
  preview: false,
}

export default React.memo(CallToAction)
