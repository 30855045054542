export const SALE_CAMPAIGNS = {
  feirao: /(\b)feir[aã]o(\b)/i,
  corujao: /coruj[aã]o/i,
  cashback: /Cashback/i,
  blackoutOld: /(\b)blackout(\b)/i,
  blackoutDay: /blackoutDay/i,
  melhorProposta: /MelhorProposta/i,
  megaFeiraoEmgea: /MegaFeir[ãa]oEmgea/i,
  feirao15: /Feir[aã]o15/i,
  cupom20: /Cupom20/i,
  preBlackout: /preBlackout/i,
  esquentaMelhorProposta: /EsquentaMelhorProposta/i,
  cupom10: /Cupom10/i,
  cupomEmgeaOutubro10: /CupomEmgeaOutubro10/i,
  cupomEmgeaOutubro20: /CupomEmgeaOutubro20/i,
  blackout: /(\b)BlackoutEmgea(\b)/i,
  esquentaBlackoutEmgea: /(\b)EsquentaBlackoutEmgea(\b)/i,
  saldaoBlackoutEmgea: /(\b)SaldaoBlackoutEmgea(\b)/i,
  preAtivo: /Pr[eé] Ativo/i,
  facaSuaProposta: /Fa[cç]aSuaProposta/i,
}
