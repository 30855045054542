import React from 'react'
import { Modal } from 'components'
import PropTypes from 'prop-types'
import { Widget } from '@typeform/embed-react'
import classNames from 'classnames'
import styles from './TypeformModal.module.scss'

const TypeformModal = ({
  formId,
  opened,
  closeModal,
  btnColor,
  dataHidden,
  pipefyUrl,
}) => {
  if (!formId && !pipefyUrl) return null
  return (
    <Modal
      opened={opened}
      closeModal={closeModal}
      className={classNames(styles.modalContainer, {
        [styles.btnWhite]: btnColor === 'white',
        [styles.pipefy]: !!pipefyUrl,
      })}
    >
      <div className={styles.formContainer}>
        {pipefyUrl ? (
          <iframe
            title="Formulário embedado do Pipefy"
            src={pipefyUrl}
            width="100%"
            height="100%"
          />
        ) : (
          <Widget
            id={formId}
            className={styles.form}
            hideFooter
            hideHeaders
            hidden={dataHidden}
          />
        )}
      </div>
    </Modal>
  )
}

TypeformModal.propTypes = {
  formId: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  btnColor: PropTypes.string,
  dataHidden: PropTypes.shape({}),
  pipefyUrl: PropTypes.string.isRequired,
}

TypeformModal.defaultProps = {
  btnColor: '',
  dataHidden: {},
}

export default React.memo(TypeformModal)
